<template>
    <div class="container">
        <div class="news">
            <div class="header">{{ info.article?.title }}</div>
            <div class="nav">
                <span>发布：{{ formatData(info.article?.updated_at) }}</span>
                <span>来源：{{ info.article?.category }}</span>
                <span>浏览：{{ info.article?.views }}</span>
            </div>
            <div class="content" v-html="info.article?.content"></div>
            <div class="news_footer">
                <div v-if="info.prev">
                    <span @click="changToDetail(info.prev.article_id)">上一篇</span>
                    <span>{{ info.prev?.title }}</span>
                </div>
                <div v-if="info.next">
                    <span @click="changToDetail(info.next.article_id)">下一篇</span>
                    <span>{{ info.next?.title }}</span>
                </div>
            </div>
        </div>
        <div class="hot">
            <div class="subTitle">热门资讯</div>
            <div class="hot_content">
                <div class="hot_list">
                    <div class="hot_list_item" v-for="(item,index) in info.hot_articles" :key="index" @click="changToDetail(item.article_id)">
                        <span class="num first" v-if="index+1 == 1">{{ index+1 }}.</span>
                        <span class="num second" v-else-if="index+1 == 2">{{ index+1 }}.</span>
                        <span class="num third" v-else-if="index+1 == 3">{{ index+1 }}.</span>
                        <span class="num" v-else>{{ index+1 }}.</span>
                        <span class="label">{{ item.title }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="new">
            <div class="subTitle">最新活动</div>
            <div class="news_list">
                <div class="news_list_item" v-for="(item,index) in info.latest_events" :key="index" @click="changToDetail(item.article_id)">
                    <img v-lazy="item.cover_image">
                    <div class="info">
                        <span class="title">{{ item.title }}</span>
                        <span>{{ formatData(item.updated_at) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <mobile-Loading v-if="isLoading"/>
    </div>
</template>
<script>
import { getNewsDetail } from '@/api/news'
import moment from 'moment';
export default {
    data() {
        return {
            hotList: [
                {
                    label: '[喜讯]龙归城-简·寓青年社区盛大开盛大开大开盛大开大开盛大开大开盛大开大开盛大开大'
                },
                {
                    label: '[喜讯]龙归城-简·寓青年社区盛大开盛大开大开盛大开大开盛大开大开盛大开大开盛大开大'
                },
                {
                    label: '[喜讯]龙归城-简·寓青年社区盛大开盛大开大开盛大开大开盛大开大开盛大开大开盛大开大'
                },
                {
                    label: '[喜讯]龙归城-简·寓青年社区盛大开盛大开大开盛大开大开盛大开大开盛大开大开盛大开大'
                },
                {
                    label: '[喜讯]龙归城-简·寓青年社区盛大开盛大开大开盛大开大开盛大开大开盛大开大开盛大开大'
                }
            ],
            info: {},
            isLoading: false
        }
    },
    mounted() {
        let id = this.$route.query.id
        this.initData(id)
    },
    methods: {
        async initData(id) {
            try {
                this.isLoading = true
                const { data:res } = await getNewsDetail(id)
                this.info = res
            }finally {
                this.isLoading = false
            }
        },
        changToDetail(id) {
            this.$router.push(`/newsDetail?id=${id}`)
        }
    },
    computed: {
        formatData() {
            return (date) => {
                return moment(date).format('YYYY-MM-DD')
            }
        }
    },
    watch: {
        $route(n) {
            this.initData(n.query.id)
        }
    }
}
</script>
<style scoped lang="scss">
.first {
    color: #FF4D16!important;
}
.second {
    color: #FF8416!important;
}
.third {
    color: #FFB516!important;
}
.subTitle {
    color: #333333;
    font-size: 32px;
    font-weight: bold;
    margin-left: 32px;
}
.container {
    background: #F5F5F5;
    .news {
        padding: 40px 30px;
        box-sizing: border-box;
        background: #fff;
        .header {
            font-size: 32px;
            color: #333333;
            font-weight: bold;
            line-height: 48px;
        }
        .nav {
            margin: 30px 0;
            display: flex;
            column-gap: 38px;
            color: #999999;
            font-size: 24px;
        }
        .content {
            min-height: 500px;
            ::v-deep img {
                width: 100%;
                height: auto;
                // object-fit: cover;
            }
        }
        &_footer {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            div {
                display: flex;
                column-gap: 30px;
                align-items: center;
                span:nth-child(1) {
                    font-size: 28px;
                    color: #2D8BFF;
                    min-width: 100px;
                }
                span:nth-child(2) {
                    font-size: 24px;
                    color: #666666;
                    display: inline-block;
                    white-space: nowrap; /* 禁止换行 */
                    overflow: hidden; /* 隐藏溢出内容 */
                    text-overflow: ellipsis; /* 显示省略号 */
                }
            }
        }
    }
    .hot {
        margin-top: 24px;
        box-sizing: border-box;
        background: #FFFFFF;
        padding-top: 50px;
        box-sizing: border-box;
        &_content {
            border-radius: 6px;
            padding: 40px 30px;
            box-sizing: border-box;
            .hot_list {
                font-size: 28px;
                color: #333333;
                display: flex;
                flex-direction: column;
                row-gap: 40px;
                &_item {
                    display: flex;
                    column-gap: 20px;
                    transition: all 0.3s ease;
                    .num {
                        font-size: 30px;
                        font-weight: bold;
                        color: #CCCCCC;
                    }
                    .label {
                        width: 100%;
                        display: inline-block;
                        align-items: center;
                        white-space: nowrap; /* 禁止文本换行 */
                        overflow: hidden; /* 隐藏超出显示范围的内容 */
                        text-overflow: ellipsis; /* 显示省略号 */
                    }
                    &:active {
                        color: #ffa71d;
                    }
                }
            }
        }
    }
    .new {
        padding: 50px 30px 20px 30px;
        box-sizing: border-box;
        background: #fff;
        margin-top: 24px;
        .subTitle {
            margin-left: 0px;
            margin-bottom: 30px;
        }
        .news_list {
            width: 100%;
            overflow-x: scroll;
            display: flex;
            column-gap: 18px;
            &::-webkit-scrollbar {
                width: 0;
                background: transparent;
            }
            &_item {
                flex-shrink: 0;
                width: 320px;
                border-radius: 6px;
                overflow: hidden;
                background: #fff;
                img {
                    width: 100%;
                    height: 220px;
                }
                .info {
                    padding: 20px;
                    box-sizing: border-box;
                    color: #999999;
                    font-size: 24px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    .title {
                        color: #333333;
                        font-size: 28px;
                        font-weight: bold;
                        white-space: nowrap; /* 禁止换行 */
                        overflow: hidden; /* 隐藏溢出内容 */
                        text-overflow: ellipsis; /* 显示省略号 */
                    }
                }
            }
        }
    }
}
</style>